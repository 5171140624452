import React, { useEffect, useState ,useCallback } from "react";
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from "react-redux";

import Button  from "@mui/material/Button";
import IconButton  from "@mui/material/IconButton";
import Tooltip  from "@mui/material/Tooltip";

import useApi from "../../api/useAPi";
import Card from "../../Components/Card/Card";
import Table from "../../Components/Table/Table";

import "./Dashboard.scss";

import linebr from "../../assets/linebr.svg";
import optmor from "../../assets/optmor.svg";
import user from "../../assets/contactIcon.svg";
import { ReactComponent as UploadIcon } from './../../assets/uploadIcon.svg';
import apiUrls from "../../api/apiUrl";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { fetchBooksList, setConcurrentEditsData, setFlowType, setTransformedData, setUserOpenedBookInfo } from "../../App/DashboardActions/action";
import { collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../../firebase";
import { useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Footer from "../../Components/Footer/Footer";
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import MoreVert from '@mui/icons-material/MoreVert';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import useStatus from "../../hooks/useStatus";
import { CONCURRENT_EDITS_TRACKER } from "../../utils/fireStoreTypes";
import EditGlossaryModal from "../../Components/EditGlossaryModal/EditGlossaryModal";
import LanguageFilter from "../../Components/LanguageFilter/LanguageFilter";
import TableRG from "../../Components/TableRG/TableRG";

const Dashboard = ({ 
/* @review-glossary-changes */
  flowType 
}) => {
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const myParam = searchParams.get('test');
  const location = useLocation();
  const [translating,setTranslatingData] = useState([])
  const dispatch = useDispatch();
  const { fetchData } = useApi();
  const { isDutchUploadInput } = location.state || {};
  const type = isDutchUploadInput ? "Pairing" : "Translation";
  const [restrictedBookData, setRestrictedBookData] = useState([])
  const [tableData, setTableData] = useState([]);
  const isSearchVisible=["qa","dev"].includes(process.env.REACT_APP_ENVIRONMENT);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDataFoundInSearch, setIsDataFoundInSearch] = useState(true);
  const [languageSelectedOption, setLanguageSelectedOption] = useState("dutch");
  const statusHook = useStatus()
  const userId = localStorage.getItem('userId')
  useEffect(() => {
    statusHook.checkStatus(userId)
  },[userId])

  
  const { isLoading, data, isItemLoadingId } = useSelector((state) => state.main);

  useEffect(() => {
        if(data.length === 0){
          getBooksList("")
        }else{
          getBooksList(isItemLoadingId)
        }
     dispatch(setUserOpenedBookInfo({}))
     {/* @review-glossary-changes */}
     dispatch(setFlowType(flowType))
  }, []);


  const getBooksList = (isItemCallId) => {
    dispatch(fetchBooksList({ fetchData ,isItemCallId, flowType}));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const collectionRef = collection(firestore, "hc_book_translation");
      const concurrentEditsTrackerRef = collection(firestore, CONCURRENT_EDITS_TRACKER);

      const concurrentEditsUnsubscribe = onSnapshot(concurrentEditsTrackerRef, (snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        let restrictedBookList = [];
        if (process.env.REACT_APP_ENVIRONMENT === "qa") {
          restrictedBookList = newData.filter((item) => item.id === "qa_data")?.[0];
        } 
        else if(process.env.REACT_APP_ENVIRONMENT === "dev"){
          restrictedBookList = newData.filter((item) => item.id === "dev_data")?.[0];
        }
        else if(process.env.REACT_APP_ENVIRONMENT === "prod") {
          restrictedBookList = newData.filter((item) => item.id === "prod_data")?.[0];
        }
        setRestrictedBookData(restrictedBookList || [])
        dispatch(setConcurrentEditsData(restrictedBookList || []))
      })
      
      const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        let translatedData = [];
        if (process.env.REACT_APP_ENVIRONMENT === "qa") {
          translatedData = newData.filter((item) => item.id === "dashboard_statuses_qa")?.[0];
        } 
        else if(process.env.REACT_APP_ENVIRONMENT === "dev"){
          translatedData = newData.filter((item) => item.id === "dashboard_statuses_dev")?.[0];
        }
        else {
          translatedData = newData.filter((item) => item.id === "dashboard_statuses_prod")?.[0];
        }

        console.log("newDatashika", newData);
        console.log("translatedData", translatedData);

        const transformedData = [];
        const getStatusLabel = (status) => {
          if (status === "Completed") return "Pairing Completed";
          if (status === "Queued") return "Queued For Translation";
          if (status === "Translating") return "Translation in Progress";
          if (status === "Translated") return "AI Translation Completed";
          return status;
        };
        const order = { Translated: "", Translating: "", Queued: "" };
        if(translating?.length===0 && flowType!=="glossaryReview"){
          setTranslatingData(translatedData["Translating"])
        }
        for (const key in order) {
          if (translatedData?.hasOwnProperty(key)) {
            translatedData?.[key].forEach((tag) => {
              transformedData.push({
                title: tag.book_title,
                author: tag.author_name,
                status: getStatusLabel(key),
                pages: "NA",
                link: tag.unique_string,
                created_at_date: tag.created_at_date,
                created_at_time: tag.created_at_time,
                temperature: tag.temperature,
                assistant_name: tag.assistant_name
              });
            });
          }
        }
        //console.log("getting data",,newData[1])
        //setData(newData);
        if(flowType!=="glossaryReview"){
          dispatch(setTransformedData(transformedData));
        }
      });

      return () =>{ 
        unsubscribe()
        concurrentEditsUnsubscribe()
      };
    };

    fetchData();
  }, []);



  useEffect(()=>{
    if(translating?.[0]?.unique_string  === data?.[0]?.link){
      if(data?.[0]?.status==="AI Translation Completed"){
        toast.success('AI translation completed successfully!')
      }
    }
  },[data?.[0]?.status, translating])

 

  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove all cookies 
    // @todo 
    // removeCookie('__Secure-next-auth.session-token');
    // console.log(cookies, 'cookies');
    // window.location.reload();
    // Redirect to the desired URL after logout
    // window.location.assign(process.env.REACT_APP_AUTH_URL_LOGOUT);
    let redirectionUrl=window.location.href
    if (redirectionUrl.endsWith("/")) {
      redirectionUrl = redirectionUrl.slice(0, -1);
    }
    window.location.href = 'https://appsecauth-ai.techo.camp/cross-signout?redirect_url=' + redirectionUrl;

  };
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  
  useEffect(() => {
    // Update filteredData whenever data changes
    let filteredData=data.filter((bookData)=>  bookData?.assistant_name?.split?.("-")[0]===languageSelectedOption)
    setFilteredData(filteredData);
  }, [data]);

 const debouncedSearch = useCallback(debounce((term) => {
  if (term && data.length > 0) {
    const lowercasedSearchTerm = term.toLowerCase();
    const newFilteredData = data.filter(item =>
      item.title.toLowerCase().includes(lowercasedSearchTerm) ||
      item.author.toLowerCase().includes(lowercasedSearchTerm) ||
      item.status.toLowerCase().includes(lowercasedSearchTerm)
    );
    if(newFilteredData.length>0){
      setIsDataFoundInSearch(true)
    }else{
      setIsDataFoundInSearch(false)
    }
    setFilteredData(newFilteredData);
  } else {
    setFilteredData(data);
  }

  if(!term)
    setIsDataFoundInSearch(true)
}, 500), [data]);

const handleSearchChange = (e) => {
  const value = e.target.value;
  setSearchTerm(value);
  debouncedSearch(value);
};
  return (
    <div className="w-screen h-screen overflow-hidden">
      <header className="bg-[#f5f4ff] dashboard-header w-full md:p-4 flex flex-col md:flex-row md:items-center gap-2 fixed top-0 left-0 z-10">
        <div className="dashboard-title font-bold flex">
          <svg
            style={{ width: "auto", height: "40px" }}
            width="24.8"
            height="58.3"
            viewBox="0 0 248 583"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M64.5 489V510C69.7 503.2 79 499.167 83 498L126.5 477C136.833 472.167 159.2 460.2 166 451C172.8 441.8 180.5 427.833 183.5 422C157.5 434.167 101 460.4 83 468C68.2 471.6 64.5 483.5 64.5 489Z"
              fill="#252384"
            />
            <path
              d="M45.7984 475C42.9984 497.8 48.2984 516.5 51.2984 523C42.465 509.5 21.8983 476 10.2983 450C-4.20165 417.5 -0.201651 401.5 3.29835 381.5C4.49835 387.9 9.46502 399.167 11.7983 404C19.7984 418.167 37.7984 452.2 45.7984 475Z"
              fill="#252384"
            />
            <path
              d="M51.2984 438C51.2984 447.6 48.6317 457 47.2983 460.5C46.7983 458.833 45.3984 454.7 43.7983 451.5C41.7983 447.5 16.2983 402 7.29834 384C0.29834 365.5 6.29834 353.5 13.7983 336.5C15.3983 344.1 19.465 353.667 21.2983 357.5C24.6317 365.5 33.2983 385.3 41.2983 400.5C51.2983 419.5 51.2984 426 51.2984 438Z"
              fill="#252384"
            />
            <path
              d="M63.7983 395.5L57.2983 415.5C57.2983 405.9 38.965 370.167 29.7983 353.5C25.465 345.5 17.6983 326.3 21.2983 313.5C24.8983 300.7 32.1317 294.5 35.2983 293C35.2983 295.167 35.6983 301.5 37.2983 309.5C39.2983 319.5 51.2984 347 59.7983 365.5C66.5983 380.3 65.2983 391.667 63.7983 395.5Z"
              fill="#252384"
            />
            <path
              d="M63.7983 453.5V473C66.9983 468.6 77.465 462.833 82.2983 460.5C95.7983 454 126.898 438.8 143.298 430C163.798 419 174.298 400.5 180.798 387C177.298 392.5 95.2983 427.5 80.2983 433.5C68.2983 438.3 64.2983 448.833 63.7983 453.5Z"
              fill="#252384"
            />
            <path
              d="M84.7983 387L70.7983 426C73.9983 420 84.1317 415.167 88.7983 413.5C106.965 404.667 148.098 384.5 167.298 374.5C188.098 366.9 200.965 349.667 204.798 342L215.798 311C195.632 321.333 149.398 345.1 125.798 357.5C100.198 365.5 87.7983 380.5 84.7983 387Z"
              fill="#252384"
            />
            <path
              d="M186.298 318C138.298 336 104.965 357.167 94.2983 365.5C105.498 333.1 120.965 319.333 127.298 316.5L229.798 265.5C228.632 277.833 218.298 305.6 186.298 318Z"
              fill="#252384"
            />
            <path
              d="M82.2983 357.5L70.7983 376.5C67.2983 355 53.2983 331.5 43.7983 305.5C34.2983 279.5 43.7983 275 57.2983 255C54.7983 270 65.7983 289 74.2983 318C84.2983 338 83.7983 352.667 82.2983 357.5Z"
              fill="#252384"
            />
            <path
              d="M106.798 313.5L88.7983 342C92.7983 330.5 74.2983 288.5 67.2983 263C63.7983 237 76.7983 229 88.7983 217C86.2983 221.5 94.2983 255 103.298 279C110.498 298.2 108.632 310 106.798 313.5Z"
              fill="#252384"
            />
            <path
              d="M133.798 271.5L115.798 299C115.798 287 106.798 263 97.2983 232.5C91.2983 202 103.298 198 115.798 187.5C112.798 195.5 123.798 222 130.298 243.5C137.098 259.5 135.465 268.833 133.798 271.5Z"
              fill="#252384"
            />
            <path
              d="M157.298 234.5L140.798 259.5C140.798 247.5 136.798 234.5 127.298 204C117.798 173.5 130.298 163.5 144.298 151C141.798 162.5 147.798 178 154.798 204C161.198 224.8 159.132 233 157.298 234.5Z"
              fill="#252384"
            />
            <path
              d="M137.798 285.5L122.298 313.5C135.798 299.5 178.798 285.5 208.298 271.5C237.798 257.5 234.798 250.5 239.798 228.5C235.298 235 196.798 251 171.798 263C151.798 269 140.798 280.5 137.798 285.5Z"
              fill="#252384"
            />
            <path
              d="M167.298 241L151.298 267C172.298 250.5 199.298 243.5 223.298 232.5C247.298 221.5 243.298 211 244.798 196.5C241.298 205.5 213.798 213 192.798 223C174.398 230.6 168.132 238.167 167.298 241Z"
              fill="#252384"
            />
            <path
              d="M186.298 206.5L175.298 228.5C193.298 213.5 223.298 204 235.798 196.5C248.298 189 247.798 175.5 247.298 163C245.298 167.5 208.298 187.5 200.298 191.5C193.898 194.7 188.298 202.833 186.298 206.5Z"
              fill="#252384"
            />
            <path
              d="M178.298 196.5L165.298 219C167.298 209.5 160.298 194 151.298 166C142.298 138 154.798 132.5 163.298 119.5C161.298 127.5 171.798 153 178.298 169.5C183.498 182.7 180.465 193 178.298 196.5Z"
              fill="#252384"
            />
            <path
              d="M212.298 154C198.298 164.4 193.465 183.333 192.798 191.5C196.798 188.5 223.298 173 237.798 163C252.298 153 244.798 140 239.798 124.5C234.798 134 229.798 141 212.298 154Z"
              fill="#252384"
            />
            <path
              d="M190.298 119.5C202.298 134.7 192.632 163.833 186.298 176.5C186.298 169.5 178.298 147.5 171.798 127.5C165.298 107.5 171.798 103 180.298 92.5C180.298 98 181.298 98.5 190.298 119.5Z"
              fill="#252384"
            />
            <path
              d="M212.298 124.5L204.298 156.5C208.965 153.5 221.098 143.8 232.298 129C246.298 110.5 235.798 97 233.798 86.5C231.798 90 229.798 92.5 225.798 99.5C214.598 112.7 212.132 121.667 212.298 124.5Z"
              fill="#252384"
            />
            <path
              d="M204.298 109L200.298 131.5C200.298 119.5 190.298 111.5 186.298 97C182.298 82.5 184.298 82.5 192.798 68.5C192.798 74.5 195.798 79.5 200.298 86.5C203.898 92.5 204.465 104 204.298 109Z"
              fill="#252384"
            />
            <path
              d="M216.798 80.5L215.298 111.5C220.298 101.5 220.798 103 228.798 87.5C233.786 74.7 227.21 62.1667 223.298 57.5C223.298 56.3333 222.798 55.9 220.798 63.5C216.798 72.7 216.465 78.6667 216.798 80.5Z"
              fill="#252384"
            />
            <path
              d="M197.298 73.5C200.898 79.5 204.465 87.3333 205.798 90.5C205.798 87.1667 205.498 79.1 204.298 73.5C203.098 67.9 199.132 55.5 197.298 50C195.798 55.3333 193.698 67.5 197.298 73.5Z"
              fill="#252384"
            />
            <path
              d="M205.798 41C208.598 45.8 208.632 63 208.298 71C208.298 70.8333 207.498 68.6 204.298 61C201.098 53 200.298 37 200.298 30C200.965 31.6667 202.998 36.2 205.798 41Z"
              fill="#252384"
            />
            <path
              d="M216.798 66.5C220.798 54.5 223.798 44 212.298 20.5C213.847 30.1667 216.914 52.9 216.798 66.5Z"
              fill="#252384"
            />
            <path
              d="M209.298 102C214.098 72.8 207.298 21.8333 203.298 0C204.965 2.66667 209.098 13.2 212.298 34C216.298 60 212.298 99.5 209.298 122.5C206.298 145.5 186.298 194 184.298 201.5C182.698 207.5 156.298 250.667 143.298 271.5L86.7983 370.5C81.8653 378.833 69.5591 407.7 59.7983 456.5C50.7983 507.963 68.7983 558 82.2983 582.5C69.2983 575 57.2983 545.5 54.2983 501C51.2983 456.5 67.2983 402 83.2983 367.5C99.2983 333 140.798 264.5 160.798 234.5C180.798 204.5 203.298 138.5 209.298 102Z"
              fill="#252384"
            />
            <path
              d="M110.596 509.5C75.3958 535.1 75.1667 557.667 77.5 566C76.5 564.5 72 557.5 67.0958 534C65.8958 511.6 76.2625 504.667 81.5958 504L153.596 467C149.096 473.5 134.196 491.1 110.596 509.5Z"
              fill="#252384"
            />
          </svg>
          <span>translate++ {flowType==="glossaryReview" && "| Review AI glossaries"}</span>
        </div>
        <div className="md:flex items-center gap-4">
          {/* <img src={user} alt="user" /> */}
          {/* <img src={linebr} alt="user" /> */}
          {/* <Dropdown>
            <MenuButton
              slots={{ root: IconButton }}
              slotProps={{ root: { } }}
              sx={{
                '&:hover': {
                  backgroundColor: '#aeaeae', // Set the hover background to transparent
                },
              }}
            >
              <MoreVert sx={{color: 'white'}}/>
            </MenuButton>
            <Menu>
              <MenuItem onClick={handleLogout}>Logout <ExitToAppIcon fontSize="10px"/></MenuItem>
            </Menu>
          </Dropdown> */}
          {isSearchVisible && (
            <input
              type="text"
              placeholder="Search for any book..."
              className="search-input-dashboard focus:outline-none"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          )}
          <LanguageFilter
            data={data}
            setLanguageSelectedOption={setLanguageSelectedOption}
            languageSelectedOption={languageSelectedOption}
            setFilteredData={setFilteredData}
          />

          <Button
            size="large"
            className="py-3 shrink-0 bg-[#fff]"
            variant="outlined"
            disableElevation
            onClick={() => {
              {
                /* @review-glossary-changes */
              }
              if (flowType === "glossaryReview") {
                navigate("/review-glossary/upload-books");
              } else {
                navigate("/upload-books");
              }
            }}
          >
            <div className="flex gap-2">
              <UploadIcon />
              Upload Books
            </div>
          </Button>
          <Tooltip title="Sign out">
            <IconButton onClick={handleLogout}>
              <ExitToAppIcon className="text-[#1E1C80]" />
            </IconButton>
          </Tooltip>

          {/* <img src={optmor} alt="user" onClick={handleLogout} className="optmor"/> */}
        </div>
      </header>
      {/* <div className="dashboard-sub-header">
        <div className="card-container">
          <Card title="Total Books" value={data?.length} /> */}
      {/* <Card
            title="Pairing Done"
            value={data.filter((item) => item.status === "Pairing Completed").length}
          /> */}
      {/* <Card
            title="Queued"
            value={data.filter((item) => item.status === "Queued For Translation").length}
          />
          <Card
            title="Translating"
            value={data.filter((item) => item.status === "Translation in Progress").length}
          />
          <Card
            title="Translated"
            value={data.filter((item) => item.status === "AI Translation Completed").length}
          /> */}
      {/* <Card title="Reviewed" value="0" disabled={true} />
          <Card title="In Review" value="0" disabled={true} />
          <Card title="Pending" value="0" disabled={true} /> */}
      {/* </div> */}
      {/* <div className="txt-btn-container"> */}
      {/* <input
            type="text"
            placeholder="Type text here"
            className="search-input-dashboard"
          /> */}

      {/* </div> */}
      {/* </div> */}
      {
        /* @review-glossary-changes */
      }
      {flowType === "glossaryReview" ? (
        <TableRG
          page={page}
          rowsPerPage={rowsPerPage}
          data={filteredData}
          restrictedBookData={restrictedBookData}
          fetchBooksList={() => {}}
          isLoading={isLoading}
          isDataFoundInSearch={isDataFoundInSearch}
        />
      ) : (
        <Table
          page={page}
          rowsPerPage={rowsPerPage}
          data={filteredData}
          restrictedBookData={restrictedBookData}
          fetchBooksList={() => {}}
          isLoading={isLoading}
          isDataFoundInSearch={isDataFoundInSearch}
        />
      )}
      <Footer
        data={filteredData}
        setRowsPerPage={setRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
      />
    </div>
  );
};

export default Dashboard;
